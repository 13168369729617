<script lang="ts">
	import '../app.pcss';

	import { onMount } from 'svelte';
	import { ProgressBar } from '@prgm/sveltekit-progress-bar';
	import { ModeWatcher } from 'mode-watcher';
	import { invalidateAll } from '$app/navigation';

	import { isOffline, windowWidth } from '$stores/general';
	import { throttle } from '$helpers/debounceAndThrottle';

	import { Toaster } from '$lib/components/ui/sonner';
	import { shortcutSymbol } from '$helpers/shortcutSymbol';

	let interval: NodeJS.Timeout;

	let documentVisibility: DocumentVisibilityState;

	onMount(() => {
		shortcutSymbol();
		interval = setInterval(() => isOnlineOffline(), 10000); // every 10 seconds

		$windowWidth = document.body.clientWidth;

		return () => clearInterval(interval);
	});

	const throttledInvalidate = throttle(invalidateAll, 900000); // 900000 milliseconds = 15 mins

	$: if (documentVisibility === 'visible') {
		if (!['/confirm-email/link', '/forgot/reset'].includes(window.location.pathname))
			throttledInvalidate();
	}

	function isOnlineOffline() {
		if (navigator.onLine) $isOffline = false;
		else $isOffline = true;
	}

	const handleResize = () => {
		if (document.body) $windowWidth = document.body.clientWidth;
	};
</script>

<svelte:document bind:visibilityState={documentVisibility} />
<svelte:window on:resize={handleResize} />

<ProgressBar class="text-black dark:text-white" zIndex={100} />

<Toaster></Toaster>
<ModeWatcher></ModeWatcher>
<slot />
